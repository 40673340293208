import * as React from "react";
import styled, { ThemeContext } from "styled-components";
import { Page } from "../components/styles";
import loadable from "@loadable/component";

const Garden = loadable(() => import("../components/garden"));

// markup
const GardenPage = () => {
  const themeContext = React.useContext(ThemeContext);

  return (
    <Page>
      <Garden hasLogo={false} theme={themeContext} />
    </Page>
  );
};

export default GardenPage;
